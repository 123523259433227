// These are required to leverage tbbs media queries
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/mixins";

@import "styles/CustomVariables";

// cell colors
$cell-color: #475467;

.ag-theme-quartz {
  --ag-selected-row-background-color: $primary !important;
}

.ag-root-wrapper {
  border-radius: 0;
  border: 0;
}

.ag-header-cell-text {
  color: $cell-color;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px; /* 150% */
}

.ag-cell-value {
  color: $cell-color;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
}

.ag-cell {
   border-right-color: var(--ag-row-border-color) !important;
   border-right-width: 1px !important;
   border-right-style: solid  !important;
 }