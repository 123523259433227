@import "CustomVariables";

.btn {
  display: flex;
  gap: .5rem;
  align-items: center;

  &:focus-visible {
    outline: .188rem solid $primary;
  }

  svg {
    min-width: 1.25rem;
  }
}

// We're using the bootstrap mixin to create the styles for buttons.
.btn-primary {
  @include button-variant(
    $primary,
    $primary,
    $white,
    $orange-dark,
    $orange-dark,
    $white,
    $orange-dark,
    $primary,
    $white,
    $orange-200,
    $orange-200,
    $white
  );

  text-shadow: $black 1px 0 1px;
}

.btn-outline-primary {
  &:hover,
  &:active,
  &:focus-visible {
    /* stylelint-disable-next-line declaration-no-important */
    color: $white !important;
  }
}

.btn-secondary {
  @include button-variant(
    $white,
    $primary,
    $primary,
    $orange-lighter,
    $orange,
    $orange-dark,
    $orange-lighter,
    $orange,
    $orange-dark,
    $white,
    $orange-light,
    $orange-light
  );
}

.btn-tertiary {
  @include button-variant(
    $white,
    $gray-200,
    $gray-800,
    $gray-200,
    $gray-200,
    $gray-800,
    $white,
    $primary,
    $gray-800,
    $white,
    $white,
    $gray-400
  );
}

.btn-tertiary-gray {
  @include button-variant(
    $white,
    $gray-300,
    $gray-800,
    $gray-200,
    $gray-300,
    $gray-800,
    $gray-100,
    $gray-300,
    $gray-800,
    $white,
    $white,
    $gray-400
  );
}

.btn-text {
  @include button-variant(
    transparent,
    transparent,
    $gray-800,
    transparent,
    transparent,
    $primary,
    transparent,
    $primary,
    $gray-800,
    transparent,
    transparent,
    $gray-400
  );

  padding: .25rem;
}

.btn-xl {
  padding: .75rem 1.25rem;
  font-size: $font-size-base;
}

.btn-xxl {
  gap: .5rem;
  padding: 1rem 1.75rem;
  font-size: 1.125rem;

  svg {
    min-width: 1.5rem;
  }
}

.btn-lg {
  line-height: 1.5rem;
}

.btn-icon-sm {
  padding: .25rem;
}
.btn-icon-md {
  padding: .625rem;
}

.btn-icon-lg {
  padding: .75rem;
}

.btn-icon-xl {
  padding: .875rem;
}

.btn-icon-xxl {
  padding: 1rem;
}

.primary-link {
  text-underline-offset: .625rem;

  &:focus-visible {
    border-radius: .25rem; /* stylelint-disable-line */
    outline: 2px solid $primary;
  }
}

.link-disabled {
  color: $gray-400;

  &:hover {
    text-decoration: none;
  }
}

.btn-sm {
  line-height: 1.25rem;
}