.sidebar-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  flex: 1 0 0;
  align-self: stretch;
  overflow: auto;

  .sidebar-nav {
    display: flex;
    padding-top: 32px;
    flex-direction: column;
    align-items: flex-start;
    gap: 1.5rem;
    align-self: stretch;

    .sidebar-header {
      display: flex;
      padding: 0rem 1.25rem 0rem 1.5rem;
      flex-direction: column;
      align-items: flex-start;
      align-self: stretch;

      .logo {
        width: 7.4375rem;
        height: 5.3125rem;
      }
    }

    .sidebar-navigation {
      display: flex;
      padding: 0rem 1rem;
      flex-direction: column;
      align-items: flex-start;
      gap: 0.25rem;
      align-self: stretch;
    }
  }

  .sidebar-footer {
    display: flex;
    padding: 0rem 1rem 2rem 1rem;
    flex-direction: column;
    align-items: flex-start;
    gap: 1.5rem;
    align-self: stretch;
  }
}
