// These are required to leverage tbbs media queries
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";

@import "styles/CustomVariables";

$max-width: 343px;

.filter-wrapper {
  max-height: 42.6px;
}

.filter-button {
  width: 100%;
  transition: $transition-base;
  @include media-breakpoint-up(lg) {
    width: 120px;
  }
}

.filter-button-open {
  border-bottom-color: white !important;
  border-bottom-right-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
  @include media-breakpoint-up(lg) {
    width: $max-width;
  }
}

.filter-menu {
  width: 100%;
  border-radius: 8px;
  z-index: 20;
  border-top: 0 !important;
  border-top-right-radius: 0 !important;
  border-top-left-radius: 0 !important;
  border: 1px solid $gray-200;
  @include media-breakpoint-up(lg) {
    width: $max-width;
  }
}

.filter-item {
  cursor: pointer;
  * {
    cursor: pointer;
  }
}