@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/mixins/_breakpoints.scss";
@import "styles/CustomVariables";

$sidebar-transition: 0.3s ease-in-out !default;
$mobile-topbar-height: 65px !default;
.sidebar-wrapper {
  position: fixed;
  bottom:0;
  left:0;
  width: 100vw;
  height: calc(100vh - $mobile-topbar-height);
  overflow: hidden;
  margin-top: $mobile-topbar-height;
  z-index: 2;

  @include media-breakpoint-up(lg){
    margin-top:0;
    height: 100vh;
  }

  .backdrop {
    backdrop-filter: $blur;
    position: absolute;
    bottom: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    background-color: $backdrop-color;
    display: none;

    @include media-breakpoint-up(lg){
      display: block;
    }
  }

  .sidebar {
    background: $white;
    overflow: auto;
    position: fixed;
    width: 100%;
    height: calc(100vh - $mobile-topbar-height);

    @include media-breakpoint-up(lg) {
      
      margin-top:0;

      &.right {
        right: 0;
        width: 24.5rem;
        height: 100vh;
      }
  
      &.left {
        left: 0;
        width: 24.5rem;
        height: 100vh;
      }
  
      &.top {
        top: 0;
        height: 392px;
        width: 100vw;
      }
  
      &.bottom {
        bottom: 0;
        height: 392px;
        width: 100vw;
      }
  
      &.fullscreen {
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
      }
    }
  

    
  }

  &.sidebar-visible {
    display: block;
  }

  &.sidebar-hidden {
    display: none;
  }
}
