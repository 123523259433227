/* stylelint-disable-next-line selector-max-type */
.table > thead tr th {
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  color: $gray-600;
  /* stylelint-disable-next-line declaration-no-important */
  background-color: $gray-50 !important;
}

.table > tbody tr td {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  color: $gray-600;
  line-height: 20px; /* 142.857% */
}