// These are required to leverage tbbs media queries
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/mixins";

@import "styles/CustomVariables";

.option-card {
  cursor: pointer;
  border-radius: .75rem;

  &.disabled-option-card {
    cursor: not-allowed;
  }
}

.active-option-card {
  background-color: #feece6 !important;
  border-color: $orange-300 !important;
}

.active-option-card-header {
  color: $orange-dark !important;
}

.lottery-style-card {
  border-radius: .75rem;
}

.icon-wrapper {
  width: 16px;
  min-width: 16px;
  height: 16px;
  min-height: 16px;
  padding: 3px;
}

.event-image-wrapper {
  width: 100%;
  @include media-breakpoint-up(lg) {
    max-width: 7.25rem;
  }
}

.feature-locked-input {
  background-color: $gray-100 !important;
}

.dropdown-toggle {
  &::after {
    display: none !important;
  }
  text-decoration: none !important;
}

.tableHeader {
  display: flex;
  padding: 1.25rem 1.5rem;
  font-family: Rubik;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
  color: #101828;
}

.datetime-input-group {
  // Handle the border radius when showing/hiding input groups
  input {
    @include media-breakpoint-down(lg) {
      /* stylelint-disable-next-line property-disallowed-list */
      border-top-right-radius: 0;
      /* stylelint-disable-next-line property-disallowed-list */
      border-bottom-right-radius: 0;
    }
    @include media-breakpoint-up(lg) {
      /* stylelint-disable-next-line property-disallowed-list */
      border-top-right-radius: .5rem !important;
      /* stylelint-disable-next-line property-disallowed-list */
      border-bottom-right-radius: .5rem !important;
    }
  }
  // Hide the input groups on desktop
  span {
    @include media-breakpoint-up(lg) {
      display: none;
    }
  }
}

