.input-group.has-help .input-group-text:last-child {
  color: $gray-400;
  border-left: none;
}

.input-group.has-help:not(.has-validation) > input:not(:last-child) {
  border-right: 0;
}

.form-switch{
  .form-check-input{
    background-color: $gray-300;
  }

  .form-check-input:checked {
    background-color: $primary;
    border-color: $primary;
  }
}

.form-helper {
  margin-top: .375rem;
  color: $gray-600;
}
