@import "~bootstrap/scss/functions";
@import "styles/CustomVariables";

.hero-image {
  &.bg-image {
    background-position: center;
    background-size: cover;
  }

  &.bg-yellow-gradient {
    background:
      radial-gradient(
        61.92% 100% at 50% 0%,
        $yellow-light 0%,
        $yellow 100%
      );
  }
  &.bg-blue-gradient {
    background:
      radial-gradient(
        61.92% 100% at 50% 0%,
        $blue-light 0%,
        $blue 100%
      );
  }
  &.bg-purple-gradient {
    background:
      radial-gradient(
        61.92% 100% at 50% 0%,
        $purple-light 0%,
        $purple 100%
      );
  }
  &.bg-green-gradient {
    background:
      radial-gradient(
        61.92% 100% at 50% 0%,
        $green-light 0%,
        $green 100%
      );
  }
  &.bg-pink-gradient {
    background:
      radial-gradient(
        61.92% 100% at 50% 0%,
        $pink-light 0%,
        $pink 100%
      );
  }

  &.bg-blue-pattern {
    background: url("../../assets/images/tickets/blue-ticket-texture.png"), transparentize($blue-light, .8);
  }
  &.bg-green-pattern {
    background: url("../../assets/images/tickets/green-ticket-texture.png"), transparentize($green-light, .8);
  }
  &.bg-yellow-pattern {
    background: url("../../assets/images/tickets/yellow-ticket-texture.png"), transparentize($yellow-light, .8);
  }
  &.bg-pink-pattern {
    background: url("../../assets/images/tickets/pink-ticket-texture.png"), transparentize($pink-light, .8);
  }
  &.bg-orange-pattern {
    background: url("../../assets/images/tickets/orange-ticket-texture.png"), transparentize($orange-light, .8);
  }
}
