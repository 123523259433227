// These are required to leverage tbbs media queries
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/mixins";

@import "styles/CustomVariables";

.row-left {
  width: 100%;

  @include media-breakpoint-up(lg) {
    max-width: 17.5rem;
  }
}