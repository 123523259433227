// These are required to leverage tbbs media queries
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/mixins";

@import "styles/CustomVariables";

.tab-nav {
  border-bottom: 1px solid $gray-200;
  gap: 16px;
}

.tab-nav-item {
  padding: 1px 4px 11px 4px;
  border-bottom: 1px solid transparent;
  &[aria-disabled].incomplete {
    color: $gray-400 !important;
  }
}

.active {
  color: $primary !important;
  border-color: $primary;
  margin-bottom: 0;
  border-bottom-width: 2px;
}
