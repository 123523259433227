@import "~bootstrap/scss/functions";

@import "styles/CustomVariables";

.dropdown-toggle {
  &::after {
    position: absolute;
    top: .75rem;
    right: 1rem;
    width: 1.25rem;
    height: 1.25rem;
    color: $gray-500;
    content: "";
    background:
      url("../../assets/images/chevron-down.svg") no-repeat
      center;
    border: 0 !important;
  }
}

.dropdown-toggle-inner {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-right: 18px;
}

.dropdown-menu {
  .dropdown-item {
    &.active {
      background-color: $gray-100;
    }
  }
}

.animated-arrow {
  animation: myAnim 1.64s linear 0s infinite normal forwards;
}

@keyframes myAnim {
  0% {
    animation-timing-function: ease-out;
    transform: translateX(0%);
    opacity: 0;
  }

  50% {
    animation-timing-function: ease-out;
    opacity: 1;
  }

  100% {
    animation-timing-function: ease-out;
    transform: translateX(100%);
    opacity: 0;
  }
}