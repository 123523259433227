// These are required to leverage tbbs media queries
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";

.lotteries-wrapper {
  width: 100%;
  @include media-breakpoint-up(md) {
    max-width: 68.625rem;
  }
}