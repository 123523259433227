/* stylelint-disable declaration-no-important */
@import "~bootstrap/scss/functions";
@import "styles/CustomVariables";

.remove-decendant-spacing {
  > div {
    padding: 0 !important;
    margin: 0 !important;
  }
}

.option {
  &:hover {
    background-color: $gray-50;
  }
  > * {
    display: inline-block;
  }
}

.selected {
  background-color: #F9FAFB 
}

.rowBackground {
  background-color: white;
  border-radius: .75rem;
}

.chevron {
  * {
    color: $gray-400 !important;
    /* stylelint-disable-next-line property-disallowed-list */
    transition: none;
  }
}
