@import "~bootstrap/scss/functions";
@import "styles/CustomVariables";

.avatar {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  font-weight: 700;
  /* stylelint-disable-next-line property-disallowed-list */
  border-radius: 12.5rem;

  &.avatar-img {
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &.avatar-text {
    .avatar-text-content {

      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      object-fit: cover;

      &.avatar-text-yellow-gradient {
        color: $yellow-dark;
        background: linear-gradient(180deg, $yellow-light 0%, $yellow 100%);
      }

      &.avatar-text-green-gradient {
        color: $green-dark;
        background: linear-gradient(180deg, $green-light 0%, $green 100%);
      }

      &.avatar-text-blue-gradient {
        color: $blue-dark;
        background: linear-gradient(180deg, $blue-light 0%, $blue 100%);
      }

      &.avatar-text-purple-gradient {
        color: $purple-dark;
        background: linear-gradient(180deg, $purple-light 0%, $purple 100%);
      }

      &.avatar-text-pink-gradient {
        color: $pink-dark;
        background: linear-gradient(180deg, $pink-light 0%, $pink 100%);
      }
    }
  }

  &.xs {
    width: .875rem;
    height: .875rem;
    font-size: .625rem;
    line-height: 1.125rem;
  }
  &.sm {
    width: 1.5rem;
    height: 1.5rem;
    font-size: .75rem;
    line-height: 1.125rem;
  }
  &.md {
    width: 2.5rem;
    height: 2.5rem;
    font-size: 1rem;
    line-height: 1.5rem;
  }
  &.lg {
    width: 6rem;
    height: 6rem;

    font-size: 3.75rem;
    line-height: 4.5rem;
    letter-spacing: -.075rem;
  }

  &.xl {
    width: 10rem;
    height: 10rem;

    font-size: 4.5rem;
    line-height: 5.625rem;
    letter-spacing: -.09rem;
  }

  //lg and xl have an additional border and shadow color
  &.lg,
  &.xl {
    border: 4px solid var(--base-white, #fff);
    box-shadow: 0 4px 6px -2px rgba(16, 24, 40, .03), 0 12px 16px -4px rgba(16, 24, 40, .08);
  }
}
