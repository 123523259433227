// These are required to leverage tbbs media queries
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";

@import "styles/CustomVariables";

$sidebar-width: 280px !default;

.app-wrapper {
  display: flex;
  flex-wrap: nowrap;
  margin-top: 64px; // this comes from Mobile.menu.scss
  overflow: hidden;

  @include media-breakpoint-up(lg) {
    margin-top: 0;
  }
}

.sidebar-menu {
  position: fixed;
  z-index: 2; // prevents overlap of button groups
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  width: $sidebar-width;
  min-width: $sidebar-width;
  max-width: $sidebar-width;
  min-height: 100vh;
  max-height: 100vh;
  background-color: $white;
  border-right: 1px solid $gray-200;
}

.app-content {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;
  background: #FCFCFD;

  @include media-breakpoint-up(lg) {
    padding-left: $sidebar-width;
  }

  main {
    flex: 1 0 auto;
  }

  footer {
    flex-shrink: 0;
  }
}
