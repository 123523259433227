.focused {
  color: #101828;
  background-color: var(--bs-body-bg);
  border-color: #eaecf0;
  outline: 0;
  box-shadow: 0 0 0 .25rem rgba(246, 68, 1, .25);
}

.hr-divider {
  display: block;
  width: 100%;
  margin: 0;
  border-top: .0625rem solid $gray-200;
}

.w-90 {
  width: 90%;
}