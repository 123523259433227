@import "CustomVariables";

// Add blur to bootstrap's modal back drop
.modal-backdrop {
  backdrop-filter: $blur;
}

.modal-footer {
  padding: $modal-inner-padding;
}
