@import "CustomVariables";

.badge {
  display: flex;
  align-items: center;
  max-width: fit-content;
  padding: 2px 8px;
  margin: -1px 0;
  font-family: $rubik;
  font-size: .75rem;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  border: 1px solid transparent;
  /* stylelint-disable-next-line property-disallowed-list */
  mix-blend-mode: multiply;
}

.user-tag {
  color: $gray-700;
  background: $gray-50;
  border-color: $blue-300;
}

.user-tag-blue {
  color: $blue-700;
  background: $gray-50;
  border-color: $blue-300;
}