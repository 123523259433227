// These are required to leverage tbbs media queries
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/mixins/_breakpoints.scss";
@import "~bootstrap/scss/mixins";

@import "styles/CustomVariables";

.sortable {
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  color: $gray-600;
  /* stylelint-disable-next-line declaration-no-important */
  background-color: $gray-50 !important;
}