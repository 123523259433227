@import "~bootstrap/scss/functions";
@import "styles/CustomVariables";
@import "~bootstrap/scss/mixins";

.dropdown-toggle {
  &::after {
    display: none !important;
  }
}


.lottery-data-pill-container{
  display: grid;
  grid-template-columns: repeat(3, 1fr); // Creates three columns, each taking one fraction of the available space
}

.lottery-data-pill{
  overflow: hidden;
  &>svg{
    min-width: 16px;
  }
  &>span{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.custom-progress-bar{
  background: linear-gradient(to right, $green-light, $green);
}

.lotteryCard {
  width: 100%;
  @include media-breakpoint-up(md) {
    max-width: 21.875rem;
  }
}


.lotteryCover {
  width: 100%;
  height: auto;
  aspect-ratio: 2.21;
  min-height: 100%;
  min-width: 100%;
  object-fit: cover;
}

.lotteryLogo {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  aspect-ratio: 1;
  width: 128px;
  height: 128px;
  object-fit: cover;
  overflow: hidden;
}

.lotteryStatus{
  position: absolute;
  bottom: 0.5rem;
  left: 0.5rem;
  color: white;
  overflow: hidden;
  z-index: 2;
  mix-blend-mode: normal;
}