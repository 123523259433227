// These are required to leverage tbbs media queries
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/mixins/_breakpoints";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";

@import "styles/CustomVariables";

.event-image-wrapper {
  width: 100%;
  @include media-breakpoint-up(lg) {
    max-width: 7.25rem;
  }
}