.app_footer_wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.25rem;
  align-self: stretch;
  padding: 1.5rem 0rem;

  .divider {
    height: 0.0625rem;
    width: 100%;
    background: var(--gray-200, #eaecf0);
    display: block;
  }

  .app_footer_content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
  }
}
