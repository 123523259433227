@import "~bootstrap/scss/functions";
@import "styles/CustomVariables";

.error-wrapper {
  width: 100vw;
  height: 100vh;
  
  .error-logo {
    height: 6rem;
  }

  h2.error-header{
    line-height: 0;
  }
}
