@import "~bootstrap/scss/functions";
@import "styles/CustomVariables";

.dropzone-wrapper {
  cursor: pointer;
  background: $white;
  border: 1px dashed $gray-200;

  /* stylelint-disable-next-line property-disallowed-list */
  border-radius: .75rem;
  /* stylelint-disable-next-line property-disallowed-list */
  transition: border .2s ease-in-out;

  .content {
    .upload-icon-wrapper {
      display: flex;
      width: 2.5rem;
      height: 2.5rem;
      padding: .625rem;
      color: $gray-600;
      background: $white;
      border: 1px solid $gray-200;

      /* stylelint-disable-next-line property-disallowed-list */
      border-radius: .5rem;

      box-shadow: 0 1px 2px 0 rgba(16, 24, 40, .05);

      .upload-icon {
        flex-shrink: 0;
        width: 1.25rem;
        height: 1.25rem;
      }
    }
  }

  &:hover,
  &.dragging {
    border: 2px solid $orange-700;

    .upload-text {
      color: $orange-700;
    }
  }
}

.upload-text {
  /* stylelint-disable-next-line property-disallowed-list */
  transition: all .3s ease-in-out;
}
