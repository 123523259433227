@import "~bootstrap/scss/functions";
@import "styles/CustomVariables";

.bell-icon{
  width: 1.25rem;
  height: 1.25rem;
  color: $gray-800;
}

.locked-icon{
  width: 1.25rem;
  height: 1.25rem;
  color: $gray-400;
}
