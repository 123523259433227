@import "~bootstrap/scss/functions";
@import "styles/CustomVariables";

$mobile-menu-transition: .3s ease-in-out !default;

.mobile-menu-wrapper {
  top: 0;
  right: 0;
  z-index: 10;
  width: 100%;
  max-height: 65px;
  overflow: hidden;
  /* stylelint-disable-next-line property-disallowed-list */
  transition: max-height $mobile-menu-transition;
}

.menu-open {
  /* stylelint-disable-next-line declaration-no-important */
  max-height: 100vh !important;
  overflow: auto;
}

.mobile-menu-header {
  display: flex;
  flex: 1 0 0;
  align-items: center;
  align-self: stretch;
  justify-content: space-between;
  padding: .75rem .5rem .75rem 1rem;
  border-bottom: 1px solid $gray-200;
}

.menu-toggler {
  padding-top: 7.2px;
  padding-bottom: 7.2px;
  background: transparent;
  /* stylelint-disable-next-line declaration-property-value-disallowed-list */
  border: none;
}

.mobile-menu-navigation {
  display: flex;
  flex-direction: column;
  gap: .25rem;
  align-items: flex-start;
  align-self: stretch;
  padding: 1rem .5rem 1.5rem;
}

.icons {
  color: $gray-500;
}

.mobile-menu-footer-div {
  margin: 0 1rem;
  border-top: 1px solid $gray-200;
}

.mobile-menu-footer {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  align-items: flex-start;
  align-self: stretch;
  padding: 1.5rem 1rem 2rem;
}

.backdrop {
  position: fixed;
  z-index: 9;
  background-color: $backdrop-color;
  /* stylelint-disable-next-line property-disallowed-list */
  transition: opacity $mobile-menu-transition, backdrop-filter $mobile-menu-transition, max-height $mobile-menu-transition;
}

.backdrop-hidden {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  /* stylelint-disable-next-line declaration-no-important */
  width: 100% !important;
  /* stylelint-disable-next-line declaration-no-important */
  max-height: 0 !important;
  backdrop-filter: blur(0);
  opacity: 0;
}

.backdrop-visible {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  /* stylelint-disable-next-line declaration-no-important */
  max-height: 100vh !important;
  backdrop-filter: $blur;
  opacity: 1;
}
