@import "~bootstrap/scss/functions";
@import "styles/CustomVariables";

.menu-top-navigation {
  display: flex;
  flex-direction: column;
  gap: .25rem;
  align-items: flex-start;
  align-self: stretch;
}

.menu-featured-card {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: flex-start;
  align-self: stretch;
  padding: 1.25rem 1rem;
  background: $gray-50;
  border-radius: .5rem;
}

.menu-account-info {
  display: flex;
  gap: 2.9375rem;
  align-items: flex-start;
  align-self: stretch;
  justify-content: space-between;
  width: 100%;
  padding: 1.25rem 0 0;

  border-top: 1px solid $gray-200;

  .menu-account-userinfo {
    display: flex;
    flex: 1 0 0;
    gap: .75rem;
    align-items: center;
    max-width: 64%;

    .menu-account-info-user-data {
      width: 100%;

      p {
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }
}
