@import "~bootstrap/scss/functions";
@import "styles/CustomVariables";

.nav-item-wrapper {
  display: flex;
  flex: 1 0 0;
  gap: .75rem;

  align-items: center;
  align-self: stretch;
  padding: .5rem .75rem;
  font-weight: 600;
  color: $gray-700;

  text-decoration: none;
  background: $white;

  /* stylelint-disable-next-line property-disallowed-list */
  border-radius: .375rem;

  .nav-link-icon {
    color: $gray-500;
  }

  &:hover,
  &:active,
  &:focus {
    color: $gray-900;
    text-decoration: none;
    background: $gray-50;
  }

  &.active {
    color: $gray-900;

    .nav-link-icon {
      color: $primary;
    }
  }

}
