@import "~bootstrap/scss/functions";
@import "styles/CustomVariables";

.progress-bar-wrapper {
  width: 100%;
  height: .5rem;
  overflow: hidden;
  background: $gray-200;
  /* stylelint-disable-next-line property-disallowed-list */
  border-radius: 50rem;
}

.progress-bar-content {
  height: 100%;
  background: linear-gradient(to right, $orange-light, $primary);
}
