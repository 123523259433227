// These are required to leverage tbbs media queries
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/mixins";

@import "styles/CustomVariables";

$hero-mobile-height: 112px;
$hero-desk-height: 200px;

.detail-hero {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  height: hero-mobile-height;

  @include media-breakpoint-up(lg) {
    height: $hero-desk-height;
  }
}

.detail-logo {
  margin-top: -1.5rem;
}

.ticket-icon-wrapper {
  width: 40px;
  height: 40px;
}

.lottery-style-icon-wrapper {
  width: 16px;
  min-width: 16px;
  height: 16px;
  min-height: 16px;
  padding: 3px;
}