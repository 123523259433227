@import "~bootstrap/scss/functions";
@import "styles/CustomVariables";
@import "~bootstrap/scss/mixins";

.reportCard {
  width: 100%;
  @include media-breakpoint-up(md) {
    max-width: 21.875rem;
  }
}

