// These are required to leverage tbbs media queries
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/mixins/_breakpoints";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";

@import "styles/CustomVariables";

.wrapper {
  gap: 2rem;
}
.header {
  gap: 1.5rem;
}

.content {
  gap: 1.5rem;
}

.locked-icon{
  width: 1.25rem;
  height: 1.25rem;
  color: $gray-400;
}
.bell-icon{
  width: 1.25rem;
  height: 1.25rem;
  color: $gray-800;
}

.password-validation-icon{
  width: 1.25rem;
  height: 1.25rem;
}

.invoice-status-icon{
  width: .75rem;
  height: .75rem;
}

.password-linkedin-container {
  border-color: $gray-300;
  border-radius: .75rem;
}

.plan-card{
  color: $gray-800;

  border-color: $gray-200 !important;

  &.plan-card-current{
    border-color: $orange !important;
  }

  .plan-card-current-header {
    background: linear-gradient(90deg, $orange-light, $orange);
    border-radius: .5rem .5rem 0 0;
  }
}

.event-image-wrapper {
  width: 100%;
  @include media-breakpoint-up(lg) {
    max-width: 7.25rem;
  }
}

.toggler-container{
  width: 100%;

  //On lg screen sizes and up width is 17rem
  @include media-breakpoint-up(lg) {
    width: 17rem;
  }
}
