@import "CustomVariables";

// Typography Module
// ––––––––––––––––––––––––––––––––––––––––––––––––––

// Custom line-heights and letter spacing
h1,
.h1 {
  line-height: 5.625rem;
  letter-spacing: -.09rem;
}
h2,
.h2 {
  line-height: 4.5rem;
  letter-spacing: -.075rem;
}
h3,
.h3 {
  line-height: 3.75rem;
  letter-spacing: -.06rem;
}
h4,
.h4 {
  line-height: 2.75rem;
  letter-spacing: -.045rem;
}
h5,
.h5 {
  line-height: 2.375rem;
  letter-spacing: 0;
}
h6,
.h6 {
  line-height: 2rem;
  letter-spacing: 0;
}

.fs-1,
.fs-2,
.fs-3,
.fs-4,
.fs-5,
.fs-6,
.h6,
.h5{
  letter-spacing: 0;
}

.fs-1 {
  line-height: 1.875rem;
}
.fs-2 {
  line-height: 1.75rem;
}
.fs-3 {
  line-height: 1.5rem;
}
.fs-4 {
  line-height: 1.25rem;
}
.fs-5 {
  line-height: 1.125rem;
}
.fs-6 {
  line-height: 1rem;
}

// Custom font weight
.fw-boldest {
  font-weight: 900;
}

.text-muted {
  /* stylelint-disable-next-line declaration-no-important */
  color: $gray-400 !important;
}

b, strong{
    font-weight: 700;
}
