@import "~bootstrap/scss/functions";

@import "styles/CustomVariables";

.mobile {
  .dropdown {
    .dropdown-toggle {
      &::after {
        position: absolute;
        top: .75rem;
        right: 1rem;
        width: 1.25rem;
        height: 1.25rem;
        color: $gray-500;
        content: "";
        background:
          url("../../assets/images/chevron-down.svg") no-repeat
          center;
        border: 0;
      }
    }
  }
}

.dropdown-menu {
  .dropdown-item {
    &.active {
      background-color: $gray-100;
    }
  }
}
