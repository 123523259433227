// These are required to leverage tbbs media queries
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";

.ticketsWrapper {
  width: 100%;
  @include media-breakpoint-up(md) {
    max-width: 68.625rem;
  }
}

.dropdown-toggle {
  &::after {
    display: none !important;
  }
}

.ticket {
  width: 100%;
  @include media-breakpoint-up(md) {
    max-width: 21.875rem;
  }
}

.flipHorizontal {
  transform: scaleX(-1);  
}

.fillStyle {
  transform: scaleX(-1);
  position: absolute;
  border-bottom: 1px solid  $gray-300;
  // width: 5%;
}

.doubleHorizontal {
  transform: scaleX(-1);
  border-bottom: 1px solid  $gray-300;
}

.ticketContainer {
  border-top: 1px solid $gray-300;
  border-bottom: 1px solid $gray-300;
}

.title {
  color: $gray-900;
  font-family: Rubik;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.875rem;
}

.ticketDetails {
  color: $gray-900;
  font-family: Rubik;
  font-size: 1.875rem;
  font-weight: 400;
  gap: 3rem;
}

.ticketDetails > div {
  width: 33%;
}

.claimedTicketDetails {
  color: $gray-900;
  font-family: Rubik;
  font-size: 1.875rem;
  font-weight: 400;
}

.ticketCounter {
  color: $gray-600;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;  
  margin-left: 0.2rem;
}

.verticalLine {
  border-right: 1px solid $gray-300;
}

.horizontalLine {
  border-top: 1px solid $gray-300;
}

.noTicket {
  color: $gray-600;
  text-align: center;
  font-family: Rubik;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5rem;
}

.paginationText {
  color: $gray-700;
  font-family: Rubik;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}

.lotteryImagesContainer {
  position: relative;
}

.lotteryCover {
  width: 100%;
  height: auto;
  aspect-ratio: 2.21;
  min-height: 100%;
  min-width: 100%;
  object-fit: cover;
}

.lotteryLogo {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  aspect-ratio: 1;
  width: 128px;
  height: 128px;
  object-fit: cover;
  overflow: hidden;
}

.attending {
  color: var(--Moss-500, #669F2A);
  font-family: Rubik;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  margin-left: 0.5rem;
}

.nextButton, .prevButton {
  padding: 4px;
  align-items: flex-start;
  border-radius: 1.25rem;
  background: $gray-800;
  box-shadow: 0px 2px 4px -2px rgba(16, 24, 40, 0.06), 0px 4px 8px -2px rgba(16, 24, 40, 0.10);
  &:disabled {
    background-color: $gray-400;
    border-color: $gray-400;
  }
}

.prevButton {
  position: absolute;
  left: -12px;
}

.nextButton {
  position: absolute;
  right: -12px;
}

.paginationButtons {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
}

.ticketPagionationWrapper {
  position: relative;
  width: 100%;
}

.lotteryStatus{
  position: absolute;
  bottom: 0.5rem;
  left: 0.5rem;
  color: white;
  overflow: hidden;
  z-index: 2;
  mix-blend-mode: normal;
}